import { useContext } from "react"
import { HeaderContext } from "./Header"

const HeaderIntLink = ({children, href, onClick}) => {
    const {setOpen} = useContext(HeaderContext);
    const handleClick = (e) => {
        e.preventDefault();
        setOpen(false);
        setTimeout(() => {
            onClick();
        }, 100);
    }
    return (
        <a onClick={handleClick} href={href}>{children}</a>
    )
} 

export default HeaderIntLink;