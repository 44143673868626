import axios from "axios";
import Modal from "./UI/Modal";
import Form from "./Form/Form";
import Input from "./Form/Input";
import Checkbox from "./Form/Checkbox";
import { APIURL } from "..";
import { useToast } from "./UI/useToast";
import { useEffect, useState } from "react";
import Loading from "./UI/Loading";

const FormModal = ({ state, type }) => {
    const { addToast } = useToast();
    const [formData, setFormData] = useState({});

    const handleSubmit = (data) => {
        let [val, set] = state;
        var dataForm = formData?.inputs.map((d, i) => ({
            id: d.id,
            value: data[d.name],
        }));
        axios
            .post(APIURL + "/newAnswer", {
                data: dataForm,
                name: type,
                language: "cz",
                message: "",
            })
            .then((response) => {
                set(false);
                addToast({ type: "success", message: "Odesláno, děkujeme." });
            })
            .catch((err) => {
                set(false);
                addToast({
                    type: "error",
                    message:
                        "Nastala se chyba, kontaktujte nás prosím na admin@agenturagen.cz.",
                });
            });
    };

    const getForm = async () => {
        await axios.post(APIURL + "/form", { name: type, language:"cz" }).then((response) => {
            setFormData(response.data.body);
        });
    };

    useEffect(() => {
        type && getForm();
    }, []);

    return (
        <Modal state={state}>
            <div className="formModal form">
                {formData ? (
                    <>
                        <div className="perex">
                            <h2>
                                <mark>{formData?.display_name}</mark>
                            </h2>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            {formData?.inputs?.map(
                                (input, index) =>
                                    input.type == "input" && (
                                        <>
                                            <Input
                                                id={input.id}
                                                label={input.label}
                                                name={input.name}
                                                placeholder={input.placeholder}
                                                className="half"
                                                required={
                                                    input.required
                                                        ? true
                                                        : false
                                                }
                                                type={input.input_type}  
                                            />
                                        </>
                                    )
                            )}
                            <Checkbox name="GDPR_form" required>
                                Souhlasím s{" "}
                                <a href='../GDPR.pdf' target="_blank">
                                    GDPR
                                </a>
                            </Checkbox>
                            <button type="submit">
                                {type === "study"
                                    ? "Zažádat studii"
                                    : "Registrovat se"}
                            </button>
                        </Form>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
        </Modal>
    );
};

export default FormModal;
