const Conference = ({ buttonClick }) => {
    return (
        <section className="conference" id="conference">
            <div className="perex">
                <p>Konference</p>
                <h2>
                    Přichází <mark>Generace&nbsp;Z</mark>
                </h2>
            </div>
            <p>
                Strugglíte s&nbsp;hiringem Gen&nbsp;Z? Zveme vás na konferenci
                pro HR profesionály,
                <br /> která se zaměřuje na nástup generace&nbsp;Z na pracovní
                trh.
            </p>
            <div className="conference__box" id="registrace">
                <div className="conference__box__left">
                    <div className="conference__box__left__circle">
                        <strong>14. 5. 2024</strong>VŠE Praha
                    </div>
                </div>
                <div className="conference__box__right">
                    <h3>Na co se můžete těšit?</h3>
                    <strong>Jaká je česká generace Z?</strong>{" "}
                    <mark>14:00</mark>
                    <br />
                    <ul>
                        <li>
                            <span>Ota Novotný </span>(VŠE, Gensight)
                        </li>
                        <li>
                            <span>Jan Císař </span>(Strateggo, Gensight)
                        </li>
                        <li>
                            <span>Adéla Kristýna Vaňková </span>(Strateggo,
                            Gensight)
                        </li>
                    </ul>
                    <strong>Mladí radí: práce nebo život?</strong>
                    <br />
                    <ul>
                        <li>
                            <span>Radek Tahal</span> (FPH VŠE)
                        </li>
                        <li>
                            <span>Jan Císař</span> (Strateggo, GenSight)
                        </li>
                        <li>
                            <span>Zdeněk Vondra</span> (VŠE, GenSight)
                        </li>
                    </ul>
                    <strong>
                        Pohledem manažerů: Jak motivovat Gen Z, aby jí práce
                        dávala smysl.
                    </strong>{" "}
                    <mark>15:00</mark>
                    <br />
                    <ul>
                        <li>
                            <span>Petr Honzejk a Veronika Capáková</span>
                            (Autoři podcastu Generační konflikt)
                        </li>
                        <li>
                            <span>Tomáš Jaroš</span>(ČSOB, Výkonný ředitelem pro
                            lidské zdroje)
                        </li>
                        <li>
                            <span>Dana Bérová</span>(investorka, bývalá
                            ministryně informatiky)
                        </li>
                    </ul>
                    <strong>Jak na to: případové studie</strong>{" "}
                    <mark>16:00</mark>
                    <br />
                    <ul>
                        <li>
                            <span>Hana Kasa</span> (ČSOB)
                        </li>
                        <li>
                            <span>Jitka Bendová</span> (Valeo)
                        </li>
                    </ul>
                    <button
                        disabled
                        type="button"
                        className="disabled"
                        onClick={buttonClick}
                    >
                        Událost již proběhla
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Conference;
