import { createContext, useContext, useState } from "react";
import "./Toast.scss"
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = ({type, message, autoclose=false}) => {
      let toastId = Date.now();
      let timeout = autoclose ? setTimeout(() => deleteToast(toastId), autoclose) : null;
      setToasts([...toasts, {id: toastId, message, type, timeout}]);
    }

    const deleteToast = (toastId) => {
      setToasts(toasts.filter(t=>t.id!==toastId))
    }

    return <ToastContext.Provider value={{addToast, deleteToast}}>
      {children}
      <TransitionGroup component='div' className='supertoasts'>
        {toasts.map(t=>
          <CSSTransition key={t.id} timeout={300} classNames='toast' ><Toast {...t} /></CSSTransition>
        )}
      </TransitionGroup>
    </ToastContext.Provider>;
};

export const useToast = () => {
  return useContext(ToastContext);
};

const Toast = ({id, type='info', message}) => {
  const {deleteToast} = useToast();
  return (
      <div className={'supertoast '+type}>
        <p>{message}</p>
        <button onClick={()=>deleteToast(id)}>&times;</button>
      </div>
  )
}