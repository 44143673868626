import { useLenis } from '@studio-freight/react-lenis';
import { useEffect, useRef, useState } from 'react';

function HashScroll() {
    const lenis = useLenis();
    const [hash, setHash] = useState(window.location.hash?.slice(1));
    const lastHash = useRef('');

    useEffect(() => {
      function handleHash(){
        setHash(window.location.hash?.slice(1));
      }
      window.addEventListener('hashchange', handleHash);
      return () => {
        window.removeEventListener('hashchange', handleHash);
      };
    },[]);


  useEffect(() => {
    lenis?.scrollTo(0, {immediate:true})
    if (hash&&lenis) {
      lastHash.current = hash;
    }
    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        lenis?.scrollTo(document.getElementById(lastHash.current));
        window.history.replaceState(null, null, ' ');
        lastHash.current = '';
      }, 100);
    }
  }, [hash, lenis]);

  return null;
}

export default HashScroll;