import { useState } from 'react';
import intropic from '../../assets/img/intropic.png';
import Modal from '../UI/Modal';
import ReactPlayer from "react-player"
const Intro = () => {
    const [modal, setModal] = useState(false);
    return (
        <>
            <section className="intro" id='intro'>
                <div className="intro__title">
                    <h1>
                        Generační vhled
                        <mark>podložený daty</mark>
                    </h1>
                    <button type='button' onClick={() => setModal(true)}>Spustit video</button>
                </div>
                <div className="intro__video" onClick={() => setModal(true)}>
                    <img src={intropic} alt='Open video' />
                </div>
            </section>
            <Modal state={[modal, setModal]}>
                <ReactPlayer url='https://vimeo.com/video/925895417' controls playing width="100%" />
            </Modal>
        </>
    );
}

export default Intro
