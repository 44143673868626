import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/main.scss';
import App from './App';

const host = window.location.host;
const http = window.location.protocol;
let a = host;
if(host.includes('localhost'))  a = '127.0.0.1:8000';
if(host.includes('devel'))  a = 'devel.agenturagen.cz/gensight';
if(host.includes('gensight'))  a = 'gensight.cz/index.php';
export const APIURL = http+"//"+a+"/api";

const root = ReactDOM.createRoot(document.body);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
